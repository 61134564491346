

import React, { Component, Fragment } from 'react';
import { Checkbox, Radio } from 'antd';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { computed, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ActionsMenu from 'src/components/common/actions-menu';
import AppIcon from 'src/components/common/app-icon';
import AppModal from 'src/components/common/app-modal';
import { QuickForm } from 'src/components/forms/quick-form';
import { Required } from 'src/components/forms/quick-form/validation';
import { transactionActionsMenu } from 'src/models/transactions/actions-menu/actions-menu';
import { triggerTransactionAction } from 'src/models/transactions/actions-menu/actions-menu-handlers';
import { OPERATION_CLONE_TRANSACTION } from 'src/models/transactions/transaction';
import addNewTransaction from 'src/utils/add-new-transaction';
import {
  disposeListener as disposeMobileBridgeListener,
  receiveMessage as receiveMobileBridgeMessage,
  sendMessage as sendMobileBridgeMessage,
} from 'src/utils/mobile-bridge';
import stopPropagation from 'src/utils/stop-propagation';
import AppAlert from '../common/app-alert';
import { tmLoadModal } from '@uc-tm/modal-loader';
import appStore from 'src/stores/app-store';

const clsPrefix = 'app-transaction-actions-menu';

export function StatusItem({ selected, children }) {
  return (
    <div
      className={classNames('flex-align-center', {
        'primary-text': selected,
      })}
    >
      <AppIcon
        type="feather"
        name="check"
        className={classNames('margin-right-sm', {
          'vs-hidden': !selected,
        })}
      />
      {children}
    </div>
  );
}

StatusItem.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.any,
};

@inject('transactions', 'ui', 'router', 'features')
@observer
export default class TransactionActionsMenu extends Component {
  static propTypes = {
    transaction: PropTypes.object.isRequired,
    transactions: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    // Callback invoked on successful completion of a transaction action. The action (operation)
    // key is passed as the only argument.
    // See models/transactions/transaction for available operations.
    onActionDone: PropTypes.func,

    hideActions: PropTypes.array,
    // Call back that passes the `this.triggerHandler`` function as an arg so that a parent component
    // can trigger handlers.
    getTriggerHandler: PropTypes.func,
    features: PropTypes.object,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  triggerHandler = async (actionKey, ...args) => {
    const { transaction, onActionDone } = this.props;
    await triggerTransactionAction(
      transaction,
      actionKey,
      onActionDone,
      ...args
    );
  };

  @computed
  get transactionActions() {
    const { transaction, features, ui, hideActions } = this.props;

    let actions = transactionActionsMenu(transaction, features);
    if (hideActions && !ui.isGlideMobileApp) {
      actions = omit(actions, hideActions);
    }
    return actions;
  }

  get mobileBridgeEventNames() {
    const { transaction } = this.props;
    return {
      menu: `transaction-actions-menu.${transaction.id}`,
      option: `transaction-actions-menu-option.${transaction.id}`,
      options: `transaction-actions-menu-options.${transaction.id}`,
    };
  }

  sendMenuOptions = () => {
    const filtered = omit(this.transactionActions, [
      OPERATION_CLONE_TRANSACTION,
    ]);

    sendMobileBridgeMessage({
      data: {
        event: this.mobileBridgeEventNames.options,
        options: filtered,
      },
    });

    // TODO: remove once deprecated in iOS App
    sendMobileBridgeMessage({
      data: {
        event: 'transaction-actions-menu-options',
        options: filtered,
      },
    });

    return filtered;
  };

  waitMenuOptionsRequest = () => {
    receiveMobileBridgeMessage({
      name: this.mobileBridgeEventNames.menu,
      cb: this.sendMenuOptions,
      permanent: true,
    });

    // TODO: remove once deprecated in iOS App
    receiveMobileBridgeMessage({
      name: 'transaction-actions-menu',
      cb: this.sendMenuOptions,
      permanent: true,
    });
  };

  waitMenuOption = () => {
    receiveMobileBridgeMessage({
      name: this.mobileBridgeEventNames.option,
      cb: this.triggerHandler,
      permanent: true,
    });

    // TODO: remove once deprecated in iOS App
    receiveMobileBridgeMessage({
      name: 'transaction-actions-menu-option',
      cb: this.triggerHandler,
      permanent: true,
    });
  };

  componentDidMount() {
    const { getTriggerHandler } = this.props;

    // TODO: remove both once deprecated in mobile apps
    this.waitMenuOptionsRequest();
    this.waitMenuOption();

    if (getTriggerHandler) {
      getTriggerHandler(this.triggerHandler);
    }
  }

  componentWillUnmount() {
    const { getTriggerHandler } = this.props;
    disposeMobileBridgeListener([
      'transaction-actions-menu',
      'transaction-actions-menu-option',
      ...Object.values(this.mobileBridgeEventNames),
    ]);
    if (getTriggerHandler) {
      getTriggerHandler(undefined);
    }
  }

  renderMenuItems() {
    const actions = this.transactionActions;

    return Object.keys(actions).map((key) => {
      const action = actions[key];

      const actionLabel = !action.errorStyle ? (
        <span>{action.label}</span>
      ) : (
        <span className="error-text">{action.label}</span>
      );

      if (!action.options) {
        return <ActionsMenu.Item key={key}>{actionLabel}</ActionsMenu.Item>;
      }

      return (
        <ActionsMenu.SubMenu key={key} title={actionLabel}>
          {action.options.map((option) => (
            <ActionsMenu.Item key={`${key}:${option.key}`}>
              <span className={option.selected ? 'error-text' : ''}>
                <StatusItem selected={option.selected}>
                  {option.label}
                </StatusItem>
              </span>
            </ActionsMenu.Item>
          ))}
        </ActionsMenu.SubMenu>
      );
    });
  }

  render() {
    const transactionActions = this.transactionActions;

    const onMenuItemClick = (actionKey) => {
      let action = actionKey;
      let param;
      if (actionKey.indexOf(':') >= 0) {
        action = actionKey.split(':')[0];
        param = actionKey.split(':')[1];
      }
      this.triggerHandler(transactionActions[action].handler, param);
    };

    const props = omit(this.props, ['transaction', 'transactions', 'ui']);
    return (
      <div
        role="button"
        onClick={stopPropagation}
      >
        <ActionsMenu
          disabled={!this.props.transaction.isActionable()}
          onMenuClick={onMenuItemClick}
          {...props}
        >
          {this.renderMenuItems()}
        </ActionsMenu>
      </div>
    );
  }
}

export const CLONE_PARTS = [
  {
    label: 'Parties',
    value: 'PARTIES',
  },
  {
    label: 'Transaction Details',
    value: 'DETAILS',
  },
  {
    label: 'Folders and Documents',
    value: 'DOCUMENTS',
  },
];

@inject('router', 'ui')
@observer
export class CloneTransactionModal extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    visible: PropTypes.bool,
    hide: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.form && this.props.visible && !prevProps.visible) {
      this.form.resetValues();
    }
  }

  onOk = async () => {
    await this.form.submit();
  };

  onSave = async (values) => {
    const { router, ui, transaction, hide } = this.props;
    await addNewTransaction(router, ui, {
      ...values,
      clone_transaction: transaction.id,
    });
    hide();
  };

  render() {
    const { transaction, visible, hide } = this.props;
    return (
      <AppModal
        title="Duplicate Transaction"
        visible={Boolean(visible)}
        onCancel={hide}
        onOk={this.onOk}
      >
        <QuickForm
          ref={(form) => {
            this.form = form;
          }}
          initialValue={{
            clone_include_parts: CLONE_PARTS.filter(
              (p) => !p.include || p.include(transaction)
            ).map(({ value }) => value),
            clone_archive_original: false,
          }}
          validation={{
            clone_include_parts: [Required()],
          }}
          onSave={this.onSave}
          hideControls
        >
          {() => (
            <Fragment>
              <QuickForm.Item
                name="clone_include_parts"
                label="Select items you would like to copy into new transaction"
              >
                <Checkbox.Group className={`${clsPrefix}__clone-parts`}>
                  {CLONE_PARTS.map(({ label, value }) => (
                    <Checkbox
                      className={`${clsPrefix}__clone-parts-checkbox`}
                      value={value}
                      key={value}
                    >
                      {label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </QuickForm.Item>
              <QuickForm.Item
                name="clone_archive_original"
                label="After duplication, would you like to archive this existing transaction?"
              >
                <Radio.Group>
                  <Radio value={false}>Do not archive</Radio>
                  <Radio value>Archive</Radio>
                </Radio.Group>
              </QuickForm.Item>
            </Fragment>
          )}
        </QuickForm>
        <AppAlert type="warning" showIcon>
          <strong>Note: </strong>At this time, Seller Disclosure workflows will
          not be copied over. However, any PDF you&apos;ve generated from a
          Seller Disclosure workflow will be duplicated onto your new
          transaction.
        </AppAlert>
      </AppModal>
    );
  }
}

/**
 * Load DuplicateTransactionModal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--duplicate-transaction
 *
 * @param {{
 *   transaction: Transaction;
 * }} options
 */
export const openDuplicateTransactionModal = async (options) => {
  const { router, ui } = appStore;
  const { transaction } = options;
  try {
    const { data: template } = await tmLoadModal('tm/duplicate-transaction/0', {
      clonePartOptions: CLONE_PARTS,
      initialValues: {
        clone_include_parts: CLONE_PARTS.filter(
          (p) => !p.include || p.include(transaction)
        ).map(({ value }) => value),
        clone_archive_original: false,
      },
      onOk: async (result) => {
        const { values } = result;
        await addNewTransaction(router, ui, {
          ...values,
          clone_transaction: transaction.id,
        });
      },
    });
    return template;
  } catch (error) {
    if (error) {
      console.error('duplicate transaction modal error', error);
      return undefined;
    }
  }
  return undefined;
};
