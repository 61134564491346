import React from 'react';
import { tmLoadModal } from '@uc-tm/modal-loader';
import pick from 'lodash/pick';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import { loadApplyTemplatesModal } from 'src/components/transaction-templates/load-apply-templates-modal';
import { loadTemplateMetaModal } from 'src/components/transaction-templates/load-template-meta-modal';
import CancelTransactionModal from 'src/components/transactions/cancellation/cancel-transaction-modal';
import {
  CloneTransactionModal,
  openDuplicateTransactionModal,
} from 'src/components/transactions/transaction-actions-menu';
import {
  OPERATION_APPLY_TEMPLATES,
  OPERATION_APPROVE_CANCELLATION,
  OPERATION_ARCHIVE,
  OPERATION_CANCEL,
  OPERATION_CLONE_TRANSACTION,
  OPERATION_COPY_TRANSACTION_EMAIL,
  OPERATION_LEAVE_TRANSACTION,
  OPERATION_REACTIVATE,
  OPERATION_SAVE_AS_TEMPLATE,
  OPERATION_SET_STATUS,
  OPERATION_UNARCHIVE,
  STATUS_TEXT,
  type Transaction,
} from 'src/models/transactions/transaction';
import appStore from 'src/stores/app-store';
import {
  approveCancellation,
  partyRemove,
  reactivate,
  updateArchivalStatus,
  updateTransactionState,
} from '../intents';
import type { UIError } from 'src/stores/ui-store';
import type PropertyInfo from 'src/models/transactions/items/property-info';

type OnActionDone = () => void;

export async function triggerTransactionAction(
  transaction: Transaction,
  actionKey: string,
  onActionDone?: OnActionDone,
  ...args: unknown[]
) {
  switch (actionKey) {
    case OPERATION_APPLY_TEMPLATES:
      await applyTemplatesHandler(transaction);
      return;
    case OPERATION_APPROVE_CANCELLATION:
      await approveCancellationHandler(transaction);
      return;
    case OPERATION_ARCHIVE:
      await archiveHandler(transaction, onActionDone);
      return;
    case OPERATION_CANCEL:
      await cancelTransactionHandler(transaction);
      return;
    case OPERATION_CLONE_TRANSACTION:
      await cloneTransactionHandler(transaction);
      return;
    case OPERATION_COPY_TRANSACTION_EMAIL:
      await copyEmailHandler(transaction);
      return;
    case OPERATION_LEAVE_TRANSACTION:
      await leaveTransactionHandler(transaction);
      return;
    case OPERATION_REACTIVATE:
      await reactivateHandler(transaction);
      return;
    case OPERATION_SAVE_AS_TEMPLATE:
      await saveAsTemplateHandler(transaction);
      return;
    case OPERATION_SET_STATUS: {
      const [status] = args as [string, ...unknown[]];
      await setStatusHandler(transaction, status, onActionDone);
      return;
    }
    case OPERATION_UNARCHIVE:
      await unarchiveHandler(transaction, onActionDone);
      return;
    default:
      appStore.ui.wentWrong(`Unexpected action ${actionKey}` as UIError);
  }
}

async function applyTemplatesHandler(transaction: Transaction) {
  await fetchPropertyInfo({ transaction });
  if (await displayHeadsupModel(transaction)) {
    loadTemplateHeadsupModal({ action: 'APPLY', transaction });
  } else {
    loadApplyTemplatesModal({ transaction });
  }
}

async function displayHeadsupModel(transaction: Transaction) {
  if (transaction.isListing) {
    return false;
  }
  const propertyInfos = (await fetchPropertyInfo({ transaction })).filter(
    (propertyInfo) => propertyInfo.address
  );
  // Addressless transaction has no valid properties
  return Boolean(propertyInfos.length < 1);
}

async function fetchPropertyInfo({
  transaction,
}: {
  transaction: Transaction;
}) {
  return appStore.transactions.getFetchItems.getOrFetch({
    transactionId: transaction.id,
    kind: 'PROPERTY_INFO',
  }) as Promise<PropertyInfo[]>;
}

async function approveCancellationHandler(transaction: Transaction) {
  try {
    await appStore.transactions.dispatch(transaction.id, approveCancellation());
    toastAction(
      <span>
        <strong>{transaction.title}</strong> fully cancelled.
      </span>
    );
  } catch (err) {
    appStore.ui.wentWrong(err as UIError);
  }
}

async function archiveHandler(
  transaction: Transaction,
  onActionDone?: OnActionDone
) {
  if (appStore.features.tmModalEnabled('archive_trxn')) {
    showArchiveTransactionModal(transaction, onActionDone);
    return;
  }

  appStore.ui.confirm({
    title: 'Are you sure you want to archive this transaction?',
    okType: 'danger',
    onOk: async () => {
      try {
        await appStore.transactions.dispatch(
          transaction.id,
          updateArchivalStatus(true)
        );
        toastAction(
          <span>
            <strong>{transaction.title}</strong> has been archived.
          </span>
        );
        if (onActionDone) {
          onActionDone();
        }
      } catch (err) {
        appStore.ui.wentWrong(err as UIError);
      }
    },
  });
}

async function cancelTransactionHandler(transaction: Transaction) {
  if (appStore.features.tmModalEnabled('cancel_trxn')) {
    loadCancelTransactionModal(transaction);
    return;
  }
  appStore.ui.setCustomModal(({ onClose }) => (
    <CancelTransactionModal
      transaction={transaction}
      onCancel={onClose}
      onSuccess={onClose}
      visible
    />
  ));
}

async function cloneTransactionHandler(transaction: Transaction) {
  if (appStore.features.tmModalEnabled('duplicate_trxn')) {
    await openDuplicateTransactionModal({ transaction });
    return;
  }

  appStore.ui.setCustomModal(({ onClose }) => (
    <CloneTransactionModal transaction={transaction} visible hide={onClose} />
  ));
}

async function copyEmailHandler(transaction: Transaction) {
  const email = transaction.emailAddress;
  const textArea = document.createElement('textarea');
  document.body.appendChild(textArea);
  textArea.value = email;
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
  toastAction('Transaction email copied.', email);
}

async function leaveTransactionHandler(transaction: Transaction) {
  const me = transaction.parties?.me;

  const confirm = await appStore.ui.typeToConfirmAsync({
    title:
      'Are you sure you want to leave the transaction? ' +
      'You will no longer have access to this transaction if you do this.',
    confirmString: 'LEAVE',
  });

  if (confirm) {
    try {
      await appStore.transactions.dispatch(transaction.id, partyRemove(me));
      appStore.ui.toast({
        message: 'Successfully left the transaction.',
        type: 'success',
      });
      await appStore.router.navigatePromise('home');
    } catch (err) {
      appStore.ui.wentWrong(err as UIError);
      throw err;
    }
  }
}

async function reactivateHandler(transaction: Transaction) {
  try {
    await appStore.transactions.dispatch(
      transaction.id,
      reactivate(transaction)
    );
    toastAction(
      <span>
        <strong>{transaction.title}</strong> reactivated as{' '}
        <strong>{transaction.statusLabel}</strong>.
      </span>
    );
  } catch (err) {
    appStore.ui.wentWrong(err as UIError);
  }
}

async function saveAsTemplateHandler(transaction: Transaction) {
  if (await displayHeadsupModel(transaction)) {
    loadTemplateHeadsupModal({ action: 'SAVE', transaction });
  } else {
    loadTemplateMetaModal({
      clone: transaction,
      navigateOnOk: !appStore.ui.isGlideMobileApp,
    });
  }
}

async function setStatusHandler(
  transaction: Transaction,
  status: string,
  onActionDone?: OnActionDone
) {
  if (!status) {
    return;
  }
  try {
    await appStore.transactions.dispatch(
      transaction.id,
      updateTransactionState(status)
    );
    toastAction(
      <span>
        <strong>{transaction.title}</strong> has been set to{' '}
        {STATUS_TEXT[status as keyof typeof STATUS_TEXT].label}.
      </span>
    );
    if (onActionDone) {
      onActionDone();
    }
  } catch (err) {
    if (
      (err as Record<string, Record<string, unknown>>).params
        ?.precondition_flow_id
    ) {
      navigateToFlow(
        appStore.router,
        (err as Record<string, Record<string, unknown>>).params
          .precondition_flow_id
      );
    } else {
      appStore.ui.wentWrong(err as UIError);
    }
  }
}

async function unarchiveHandler(
  transaction: Transaction,
  onActionDone?: OnActionDone
) {
  try {
    await appStore.transactions.dispatch(
      transaction.id,
      updateArchivalStatus(false)
    );
    toastAction(
      <span>
        <strong>{transaction.title}</strong> has been unarchived.
      </span>
    );
    if (onActionDone) {
      onActionDone();
    }
  } catch (err) {
    appStore.ui.wentWrong(err as UIError);
  }
}

async function createPropertyFlow(
  transaction: Transaction,
  onCancel: () => void,
  { redirectToProperty = false } = {}
) {
  try {
    const flowParams = {
      transactionId: transaction.id,
      backRoute: pick(appStore.router.route, ['name', 'params']),
      redirectToProperty: !transaction.isSale && Boolean(redirectToProperty),
    };
    const {
      data: { flow_id: flowId },
    } = await appStore.transactions.api.addAddress(flowParams);
    onCancel();
    await navigateToFlow(appStore.router, flowId);
  } catch (err) {
    appStore.ui.wentWrong(err as UIError);
  }
}

function toastAction(
  message: string | React.ReactElement,
  description?: string
) {
  appStore.ui.toast({
    message,
    description,
    type: 'success',
  });
}

type Options = {
  action: 'APPLY' | 'SAVE';
  transaction: Transaction;
};
/**
 *
 * Load confirm modal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--confirm
 *
 * @param {{
 * action: 'APPLY' | 'SAVE'
 * transaction: Transaction
 * }} options
 */
async function loadTemplateHeadsupModal(options: Options) {
  const { action, transaction } = options || {};
  const actionTextMap = {
    SAVE: 'save',
    APPLY: 'apply',
  };

  // eslint-disable-next-line
  const noop = () => {};
  const onOk = async () => {
    await createPropertyFlow(transaction, noop, {
      redirectToProperty: appStore.ui.isEmbedded,
    });
  };

  try {
    await tmLoadModal('tm/confirm/0', {
      title: 'Heads up!',
      message: `In order to ${actionTextMap?.[action]} a template, you will need to add a property address.`,
      onOk,
      okText: 'Add a property',
      cancelButtonProps: {
        hidden: true,
      },
    });
  } catch (err) {
    if (!err) {
      return; // cancel
    }
    console.error('TemplateHeadsupModal error', err);
  }
}

/**
 * Load confirm modal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--confirm
 */
const showArchiveTransactionModal = async (
  transaction: Transaction,
  onActionDone?: OnActionDone
) => {
  try {
    tmLoadModal('tm/confirm/0', {
      title: '',
      message: 'Are you sure you want to archive this transaction?',
      closable: false,
      okText: 'OK',
      onOk: async () => {
        try {
          await appStore.transactions.dispatch(
            transaction.id,
            updateArchivalStatus(true)
          );
          toastAction(
            <span>
              <strong>{transaction.title}</strong> has been archived.
            </span>
          );
          if (onActionDone) {
            onActionDone();
          }
        } catch (err) {
          appStore.ui.wentWrong(err as UIError);
        }
      },
      okButtonProps: {
        danger: true,
      },
    });
  } catch (err) {
    if (!err) {
      return; // cancel
    }
    console.error('ArchiveTransactionModal error', err);
  }
};
async function cancelTransaction(
  transaction: Transaction,
  reason: string,
  details: string,
  force: boolean
) {
  const status =
    (transaction.userHasPerm('APPROVE_CANCELLATIONS') && force) ||
    !transaction.org
      ? 'CANCELLED'
      : 'CANCEL_REQUESTED';
  try {
    await appStore.transactions.dispatch(
      transaction.id,
      updateTransactionState(status, {
        cancellationMeta: {
          reason,
          details,
        },
        optimistic: false,
      })
    );
    appStore.ui.toast({
      type: 'success',
      message:
        status === 'CANCELLED'
          ? 'Cancelled transaction'
          : 'Requested cancellation',
    });
  } catch (err) {
    appStore.ui.wentWrong(err as UIError);
  }
}

type CancelTransactionModalOnOkParams = {
  values: {
    reason: string;
    details: string;
    force: boolean;
  };
};

/**
 * Load form modal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--cancel-transaction
 */
async function loadCancelTransactionModal(transaction: Transaction) {
  const hasApprove = transaction.userHasPerm('APPROVE_CANCELLATIONS');
  const onOk = async ({
    values: { reason, details, force },
  }: CancelTransactionModalOnOkParams) => {
    await cancelTransaction(transaction, reason, details, force);
  };

  try {
    await tmLoadModal('tm/cancel-transaction/0', {
      hasApprove,
      onOk,
    });
  } catch (err) {
    if (!err) {
      return; // cancel
    }
    console.error('CancelTransaction error', err);
  }
}
