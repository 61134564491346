import invariant from 'invariant';
import Transaction from 'src/models/transactions/transaction';
import FeaturesStore from 'src/stores/features-store';
import {
  OPERATION_APPLY_TEMPLATES,
  OPERATION_APPROVE_CANCELLATION,
  OPERATION_ARCHIVE,
  OPERATION_CANCEL,
  OPERATION_CLONE_TRANSACTION,
  OPERATION_COPY_TRANSACTION_EMAIL,
  OPERATION_LEAVE_TRANSACTION,
  OPERATION_REACTIVATE,
  OPERATION_SAVE_AS_TEMPLATE,
  OPERATION_SET_STATUS,
  OPERATION_UNARCHIVE,
  STATUSES_BY_SIDE,
  STATUS_TEXT,
} from 'src/models/transactions/transaction';

export function transactionActionsMenu(
  transaction: Transaction,
  features: FeaturesStore
) {
  const actions: any = {};

  if (features.tmPartyModalFlag && transaction.isCompassTransaction) {
    if (transaction.can(OPERATION_LEAVE_TRANSACTION)) {
      actions[OPERATION_LEAVE_TRANSACTION] = {
        label: 'Leave transaction',
        errorStyle: true,
        handler: OPERATION_LEAVE_TRANSACTION,
      };
    }
    return actions;
  }

  invariant(
    !(
      transaction.can(OPERATION_ARCHIVE) && transaction.can(OPERATION_UNARCHIVE)
    ),
    'Archive and Unarchive are exclusive operations for a transaction.'
  );

  if (transaction.can(OPERATION_SET_STATUS)) {
    const statuses: string[] =
      STATUSES_BY_SIDE[transaction.side as keyof typeof STATUSES_BY_SIDE];
    actions[OPERATION_SET_STATUS] = {
      label: 'Change Status',
      handler: OPERATION_SET_STATUS,
      options: statuses.map((status: string) => ({
        key: status,
        label: STATUS_TEXT[status as keyof typeof STATUS_TEXT].label,
        selected: transaction.state === status,
      })),
    };
  }

  if (transaction.can(OPERATION_APPLY_TEMPLATES)) {
    actions[OPERATION_APPLY_TEMPLATES] = {
      label: 'Apply Templates',
      handler: OPERATION_APPLY_TEMPLATES,
    };
  }

  if (transaction.can(OPERATION_SAVE_AS_TEMPLATE)) {
    actions[OPERATION_SAVE_AS_TEMPLATE] = {
      label: 'Save as Template',
      handler: OPERATION_SAVE_AS_TEMPLATE,
    };
  }

  if (transaction.can(OPERATION_CLONE_TRANSACTION)) {
    actions[OPERATION_CLONE_TRANSACTION] = {
      label: 'Duplicate Transaction',
      handler: OPERATION_CLONE_TRANSACTION,
    };
  }

  actions[OPERATION_COPY_TRANSACTION_EMAIL] = {
    label: 'Copy Transaction Email',
    handler: OPERATION_COPY_TRANSACTION_EMAIL,
  };

  if (transaction.can(OPERATION_CANCEL)) {
    actions[OPERATION_CANCEL] = {
      label: 'Cancel Transaction',
      handler: OPERATION_CANCEL,
    };
  }

  if (transaction.can(OPERATION_APPROVE_CANCELLATION)) {
    actions[OPERATION_APPROVE_CANCELLATION] = {
      label: 'Approve Cancellation',
      handler: OPERATION_APPROVE_CANCELLATION,
    };
  }

  if (transaction.can(OPERATION_REACTIVATE)) {
    actions[OPERATION_REACTIVATE] = {
      label: 'Reactivate Transaction',
      handler: OPERATION_REACTIVATE,
    };
  }

  if (transaction.can(OPERATION_ARCHIVE)) {
    actions[OPERATION_ARCHIVE] = {
      label: `Archive ${
        transaction.isBuyerProspect ? 'Prospect' : 'Transaction'
      }`,
      errorStyle: true,
      handler: OPERATION_ARCHIVE,
    };
  }

  if (transaction.can(OPERATION_UNARCHIVE)) {
    actions[OPERATION_UNARCHIVE] = {
      label: `Unarchive ${
        transaction.isBuyerProspect ? 'Prospect' : 'Transaction'
      }`,
      handler: OPERATION_UNARCHIVE,
    };
  }

  return actions;
}
